<template>
  <div>
    <v-container fluid>
      <v-widget title="Movimento de Estoque">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="5">
                <v-text-field flat single-line hide-details dense clearable solo v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" prepend-inner-icon="search" label="Busca" background-color="grey lighten-3"></v-text-field>
              </v-col>
              <v-col cols="2" sm="7" class="text-right">
                <v-btn v-if="!isSm" depressed color="primary" @click="novo">
                  NOVO
                </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="movimentos" :search="busca" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.data | formatarData }}</td>
                <td>{{ props.item.numero_nota }}</td>
                <td>{{ props.item.produto.codigo }}</td>
                <td>{{ props.item.produto.descricao }}</td>
                <td class="text-center">
                  <v-chip pill outlined
                    ><v-icon color="primary" left>{{ props.item.tipo === 'Entrada' ? 'mdi-plus' : 'mdi-minus' }}</v-icon
                    >{{ props.item.tipo }}</v-chip
                  >
                </td>
                <td class="text-center">{{ props.item.quantidade }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 40" @click="remover(props.item)">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: 'ListaEstoqueSimples',
  data() {
    return {
      headers: [
        { text: 'Data', value: 'data', sortable: false },
        { text: 'Nota', value: 'numero_nota', sortable: false },
        { text: 'Código', value: 'produto.codigo', sortable: false },
        { text: 'Descrição', value: 'produto.descricao', sortable: false },
        { text: 'Tipo', value: 'tipo', align: 'center', sortable: false },
        { text: 'Qtd.', value: 'quantidade', align: 'center', sortable: false },
        { text: 'Ações', value: 'name', align: 'center', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.carregar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('estoque', {
      movimentos: 'movimentos',
      carregando: 'carregando',
      total: 'totalMovimentos',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    carregar() {
      this.$store.dispatch('estoque/carregar', this.options);
    },

    limparBusca() {
      this.carregar();
    },

    filtrar(event) {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('estoque/carregar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    remover(item) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('estoque/remover', item);
        }
      });
    },

    editar(movimento) {
      this.$store.commit('estoque/editar', movimento);
      this.$router.push({ name: 'formularioEstoqueEditar' });
    },

    novo() {
      this.$store.commit('estoque/novo');
      this.$router.push({ name: 'formularioEstoqueNovo' });
    },
  },
};
</script>
